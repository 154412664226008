.container-dropdown {
  width: 100%;
  cursor: pointer;
  font-size: 13px;
  background-color: black;
  background: black;
}
.ant-select-selection {
  background-color: transparent;
}
.ant-select-selection-selected-value {
  width: 100%;
}
